<template>
  <c-box
    w="100%"
    mx="auto"
    :h="['calc(100vh - 62px)', 'auto']"
    :bg="['#F2F2F2', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    border-radius="16px"
    :p="['1rem', '1.5rem']"
    :min-height="['unset', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="breadcrumb"
      />
    </Portal>
    <c-box
      :w="['100%', '600px']"
      mx="auto"
    >
      <c-text
        :d="['none', 'block']"
        font-family="Roboto"
        font-size="28px"
        color="#111"
        font-weight="700"
        mb="1.5rem"
        :text-align="role === 'client' ? 'inherit' : 'center'"
      >
        Akun
      </c-text>
      <c-box
        v-if="user.role === 'nutritionist'"
        margin-bottom="32px"
        padding="0 32px"
      >
        <c-flex
          flex-direction="column"
          :gap="['10px', '16px']"
        >
          <CardNutritionist
            :name="getFullnameAndTitle(`${nutritionist.firstName} ${nutritionist.lastName}`.trim(), nutritionist.education)"
            :photo="nutritionist.photoUrl"
            :service-hours="nutritionist.serviceHour"
            :ratings="nutritionist.rating"
            :educations="educationsParser(nutritionist.education, universities)"
            :specializations="nutritionist.specialization || '-'"
            :remaining-quota="nutritionist.remainingQuota"
          >
            <template #button>
              <BaseButton
                border-radius="1000px"
                width="100%"
                size="medium"
                padding="0 50px"
                variant="outlined"
                @click.prevent="$router.push({ name: 'client.profile.detail' })"
              >
                Lihat Profil
              </BaseButton>
            </template>
          </CardNutritionist>

          <CardInfoReferral type="profile" />
        </c-flex>
      </c-box>
      <c-box
        :background-color="['#FFF', '#F2F2F2']"
        border-radius="1rem"
        :px="['0.5rem', '1rem']"
      >
        <c-flex
          h="64px"
          align-items="center"
        >
          <c-link
            as="router-link"
            :to="user.role === 'nutritionist'
              ? { name: 'profile.edit' }
              : { name: 'client.profile.detail' }"
            w="100%"
            display="flex"
            align-items="center"
            justify-content="space-between"
            p="16px"
          >
            <c-text
              font-family="Roboto"
              font-weight="500"
            >
              Ubah Data
            </c-text>
            <c-image
              :src="require('@/assets/ic-chevron-right-black.svg')"
              alt="icon arrow right"
            />
          </c-link>
        </c-flex>
        <c-flex
          v-if="role !== 'admin' && role !== 'super_admin'"
          h="64px"
          align-items="center"
          border-top="1px solid #C4C4C4"
        >
          <c-link
            as="router-link"
            :to="{ name: 'client.profile.settings' }"
            w="100%"
            display="flex"
            align-items="center"
            justify-content="space-between"
            p="16px"
          >
            <c-text
              font-family="Roboto"
              font-weight="500"
            >
              Ubah Password
            </c-text>
            <c-image
              :src="require('@/assets/ic-chevron-right-black.svg')"
              alt="icon arrow right"
            />
          </c-link>
        </c-flex>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import CardNutritionist from '@/components/widgets/card-nutritionist.vue'
import { educationsParser, getFullnameAndTitle } from '@/utils/specific-case-utils'
import BaseButton from '@/components/elements/base-button.vue'
import CardInfoReferral from '@/components/card-info-referral.vue'
import { flags } from '@/utils/flags'

export default {
  name: 'ProfilePage',
  components: {
    CardInfoReferral,
    BaseButton,
    CardNutritionist,
    BreadcrumbPath,
  },
  mixins: [
    generalMixin,
  ],
  data() {
    return {
      universities: {},
      nutritionist: {},
    }
  },
  computed: {
    ...mapState({
      profile: (s) => s.profile,
      user: (s) => s.auth.user,
      role: (s) => s.auth.user?.role,
    }),
    ...mapGetters({
      fullName: 'profile/fullName',
      userId: 'auth/userId',
    }),
    breadcrumb() {
      if (this.role === 'client') {
        return [
          {
            label: 'Dashboard',
            href: { name: 'client.index' },
          },
          {
            label: 'Akun',
            href: { name: 'client.profile' },
          },
          {
            label: 'Akun',
            isCurrent: true,
          },
        ]
      }

      return [
        {
          label: 'Beranda',
          href: this.role === 'nutritionist'
            ? { name: 'nutrisionist.managementClient' }
            : { name: 'admin' },
        },
        {
          label: 'Akun',
          isCurrent: true,
        },
      ]
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    educationsParser,
    getFullnameAndTitle,
    ...mapActions({
      loadUniversities: 'general/loadUniversities',
      getNutritionistDetailProgramNutritionist: 'clients/getNutritionistDetailProgramNutritionist',
      getProfileClient: 'profile/getProfileClient',
      getProfile: 'profile/getProfile',
      signout: 'auth/signout',
    }),
    logout() {
      this.signout()
        .then(() => {
          this.$store.dispatch('footer/getFooter')
          this.$store.commit('resetStore')
          flags.redirectAfterLogout(this.$route, this.$router)
        })
        .catch(() => {
          flags.redirectAfterLogout(this.$route, this.$router)
        })
    },
    async init() {
      if (this.role === 'client') {
        this.getProfileClient()
      }

      if (this.role === 'nutritionist') {
        const [
          listUniversities,
          nutritionistDetail,
        ] = await Promise.all([
          this.loadUniversities(),
          this.getNutritionistDetailProgramNutritionist(this.userId).then(v => v?.data || {}),
        ])
        this.universities = listUniversities
        this.nutritionist = nutritionistDetail

        this.getProfile()
      }
    },
  },
}
</script>
